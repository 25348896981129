import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({isSidebarOpen}) => {
  return (
    <aside className={`bg-gray-900 text-white w-64 ${isSidebarOpen ? 'block' : 'hidden'} md:block`}>
    <nav className="p-4 space-y-2">
      <Link to="/dashboard" className="block p-2 rounded hover:bg-gray-700">
        Dashboard
      </Link>
      <Link to="/analytics" className="block p-2 rounded hover:bg-gray-700">
        Analytics
      </Link>
      <Link to="/settings" className="block p-2 rounded hover:bg-gray-700">
        Settings
      </Link>
    </nav>
  </aside>
  )
}

export default Sidebar