import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard';
import DashboardLayout from '../layout/DashboardLayout';
import Profile from '../pages/Profile';

const Routes = () => {
    const appRouter = createBrowserRouter([
        {
            path: '/',
            element: <Login />
        }, {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                {
                    path:'',
                    element: <Dashboard/>
                },
                {
                    path:'profile',
                    element: <Profile/>
                }
            ],
        }
    ]);
    return (
        <div>
            <RouterProvider router={appRouter} />
        </div>
    )
}

export default Routes;