import Routes from "./components/Routes";

const App = () =>{
  return (
    <div>
        <Routes/>
    </div>
  );
}

export default App;