import React from 'react'

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white p-4 text-center">
            © 2024 TORC INFOTECH PVT LTD
        </footer>
    )
}

export default Footer