import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({ toggleSidebar }) => {
    return (
        <header className="bg-gray-800 text-white p-4 flex justify-between items-center">
         <Link to="/dashboard/profile"><img className='w-28' src="https://torcedu.com/assets/img/logo-light.png" alt="" /></Link>
            <div className="flex items-center space-x-4">
                <button onClick={toggleSidebar} className="md:hidden p-2 rounded bg-blue-600 hover:bg-blue-700">
                    Menu
                </button>
                <input
                    type="text"
                    placeholder="Search..."
                    className="p-2 rounded bg-gray-700 border-none focus:ring-2 focus:ring-blue-500 hidden md:block"
                />
              <Link to="/dashboard/profile"><img className='w-10' src="https://torcedu.com/assets/img/favicon/android-chrome-512x512.png" alt="" /></Link>
            </div>
        </header>
    )
}

export default Header