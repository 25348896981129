const Dashboard = () => {
    return (
   
    <main className="flex-1 p-4 bg-gray-100">
      <h2 className="text-xl font-bold mb-4">Hi Welcome Back</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="p-4 bg-white rounded shadow">Content Box 1</div>
        <div className="p-4 bg-white rounded shadow">Content Box 2</div>
        <div className="p-4 bg-white rounded shadow">Content Box 3</div>
      </div>
    </main>
     
    );
};

export default Dashboard;
